import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [0,2,12,13];

export const dictionary = {
		"/": [16],
		"/account/integrations": [17,[2]],
		"/account/invoices": [18,[2]],
		"/account/preferences": [19,[2]],
		"/account/profile": [20,[2]],
		"/account/subscription": [21,[2]],
		"/app": [22,[3]],
		"/checkout": [23],
		"/checkout/update-payment": [24],
		"/community/documents/[id]": [25,[4]],
		"/community/notes/[id]": [26,[4]],
		"/configure-integration/bp/setup": [27],
		"/consult": [28],
		"/create-account": [29,[5]],
		"/create-account/details": [30,[5]],
		"/create-account/password": [31,[5]],
		"/customisation/document-personalisation": [32,[6]],
		"/customisation/documents": [33,[6,7]],
		"/customisation/documents/new_document": [35,[6,7]],
		"/customisation/documents/[slug]": [34,[6,7]],
		"/customisation/find-and-replace": [36,[6]],
		"/customisation/general": [37,[6]],
		"/customisation/macros": [38,[6]],
		"/customisation/note-personalisation": [39,[6]],
		"/dictate": [40,[8]],
		"/downtime": [~41],
		"/form/[code]": [42],
		"/help": [43,[9]],
		"/history": [44,[10]],
		"/history/documents/[slug]": [~45,[10,11]],
		"/logging-in": [46],
		"/login": [47],
		"/login/mfa": [48],
		"/organisation/manage": [49,[12]],
		"/organisation/subscription": [50,[12]],
		"/organisation/usage": [51,[12]],
		"/personalisation/documents/[slug]": [52],
		"/personalisation/notes/[slug]": [53],
		"/preconsult/[code]": [54],
		"/security/mfa": [55,[13]],
		"/signin": [56,[14]],
		"/signin/mfa": [57,[14]],
		"/signin/password": [58,[14]],
		"/signup": [59],
		"/update-password": [60,[15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';